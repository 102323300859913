<script>
import { currentColorRule,lastDrawingFormat,lastDrawingRule } from '@/CONF.js'
import { getAnimals } from "@/api/touzhu.js";

export default {
    name: 'Billboard',
    data() {
        return {
            currentColorRule,
            lastDrawingFormat,
            lastDrawingRule,
            TEMPLATE2_componentDockerToggle:false,
            TEMPLATE2_auctioneerUIToggle:false,
             TEMPLATE1_componentDockerToggle:false,
            TEMPLATE1_auctioneerUIToggle:false,
            zodiacRule:null,
        }
    },
    props: [
        'countdownTimer',
        'gameType',
        'gameName',
        'game',
        'toggleComponentDocker',
        'nav',
        'mountedOn',
    ],
    inject:[
        'checkDxds',
        'highlightHorizonMenu',
        'toggleHeaderMenuDocker',
        'toggleChatRoom',
    ],
    methods: {
        _drawingFormat(){
            if( this.zodiacRule ) {
                return this.lastDrawingFormat(this.lastDrawing,this.lastDrawingRule,this.currentColorRule,this.zodiacRule)
            }else if (this.gameType.indexOf('lhc')===0) {
                getAnimals().then(result =>{
                    if(result.data.code === 'SUCCESS'){
                        this.zodiacRule=result.data.result;
                        return this.lastDrawingFormat(this.lastDrawing,this.lastDrawingRule,this.currentColorRule,this.zodiacRule)
                    }
                });
            }else if (this.lastDrawing) {
                return this.lastDrawingFormat(this.lastDrawing,this.lastDrawingRule,this.currentColorRule)
            }            
        },
        formatCurrentDrawing(data){
            return data&&data.length>8?data.slice(-8):data
        },
        formatValue(value){
            return this.gameType.indexOf('lhc')===0
                        ?value<10
                            ?'0'+value.toString()
                            :value.toString()
                        :value            
        },
        restore(){
            if (this.TEMPLATE2_componentDockerToggle===true) {
                this.TEMPLATE2_componentDockerToggle=false
                this.toggleComponentDocker()    
            }
            if (this.TEMPLATE1_componentDockerToggle===true) {
                this.TEMPLATE1_componentDockerToggle=false
                this.toggleComponentDocker()    
            }
        },
        redirectPath(path,query) {
            this._linkTo({
                path:path,
                query:query
            })
        },
        getNav(nav){
            var gameType=this.$route.query.type;
            if (gameType==='cqssc'||gameType==='bjsc'||gameType==='xyft') {
                nav.splice(3,1)
            }else {
                nav.splice(4,1)
            }
            return nav
        },
        handleHeaderMenuClick(nav,item){
            if(item.id==='5') {
                this.toggleHeaderMenuDocker(true);
                this.toggleChatRoom();
            }else {
                this.toggleChatRoom(true);
                this.highlightHorizonMenu(nav,item)
                this.toggleHeaderMenuDocker();
            }
        },
    },
    mounted() {
        this.$root.$on('TEMPLATE2_toggleComponentDocker',(data)=>{            
            this.TEMPLATE2_componentDockerToggle=data            
        })
        this.$root.$on('TEMPLATE2_toggleAuctioneerUI',(data)=>{            
            this.TEMPLATE2_auctioneerUIToggle=data            
        })
         this.$root.$on('TEMPLATE1_toggleComponentDocker',(data)=>{            
            this.TEMPLATE1_componentDockerToggle=data            
        })
        this.$root.$on('TEMPLATE1_toggleAuctioneerUI',(data)=>{            
            this.TEMPLATE1_auctioneerUIToggle=data            
        })
        if (this._TEMPLATE==='template-2'||this._TEMPLATE==='template-1') {
            this.getNav(this.nav);
        }
        if (this.gameType.indexOf('lhc')===0) {
            getAnimals().then(result =>{
                if(result.data.code === 'SUCCESS'){
                    this.zodiacRule=result.data.result;
                }
            });
        }
    },
    computed:{
        formatCountdownTimer(){
            if (isNaN(this.countdownTimer)) {
                return this.countdownTimer.substr(0,6)
            }else {
                var hours=parseInt(this.countdownTimer/3600)
                var minutes=parseInt(this.countdownTimer%3600/60)
                var seconds=parseInt(this.countdownTimer%3600%60)
                return (hours>9?hours:'0'+hours)+':'+(minutes>9?minutes:'0'+minutes)+':'+(seconds>9?seconds:'0'+seconds)
            }
        },
        userBalance(){
            return this.$store.state.userBalance && this.$store.state.userBalance['user_money'];
        },
        currentDrawing(){
            return this.$store.state.currentDrawing && this.$store.state.currentDrawing[this.gameType]
        },
        lastDrawing(){
            return this.$store.state.lastDrawing && this.$store.state.lastDrawing[this.gameType]
        },
        language(){
            return window.language
        }
    },
};
</script>
<template>
    <section id="billboard" :class="_TEMPLATE">
        <template v-if="_TEMPLATE!=='template-3'&&gameType.indexOf('28')>-1 && currentDrawing && lastDrawing && gameType!=='wanneng' && gameType!=='damacai' && gameType!=='duoduo' && gameType!=='yuedawan' && gameType!=='shaba88' && gameType!=='saimahui' && gameType!=='xinjapowz'">
            <div class="inner" @click='restore' 
            :class="{covered:(TEMPLATE2_componentDockerToggle||TEMPLATE2_auctioneerUIToggle||TEMPLATE1_componentDockerToggle) }">
                <div class="current">
                    <h6 class="index" >
                        <template v-if="!isNaN(countdownTimer)">
                            {{ getWord('until') }}<b>{{formatCurrentDrawing(currentDrawing.issue)}}</b>{{ getWord('end_at') }}
                        </template>
                        <template v-else>
                            {{ getWord('handicap_renew') }}。。。
                        </template>
                        
                        <span class="timer">{{formatCountdownTimer}}</span>
                    </h6>
                    
                    <h6 class="balance">
                        {{ getWord('account_balance') }}
                        <b :data-currency="currencySymbol()">{{userBalance}}</b>
                    </h6>
                </div>
                <div class="last" v-if="!TEMPLATE2_componentDockerToggle||!TEMPLATE1_componentDockerToggle" :class="{ 'pc28':gameType.indexOf('28')>-1 }">
                    <h4>{{ getWord('number') }}<b>{{lastDrawing && lastDrawing.issue}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <template v-if="_drawingFormat() && !_drawingFormat().length">
                            <ul v-if="gameType.indexOf('28')>-1" class="pc28">
                                <li v-for="item in 3">?</li><li style="color:#333333!important">?</li>
                            </ul>
                        </template>
                        <ul v-if="_drawingFormat() && _drawingFormat().length" :dxds="checkDxds(lastDrawing.num_count)" :class="{ lhc:_drawingFormat().length===7,pc28:_drawingFormat().length===4,dtclassic:_drawingFormat().length===10,kuai3:(_drawingFormat().length===3&&gameType.indexOf('fastthree')>-1) }">
                            <template v-if="gameType.indexOf('fastthree')===-1">
                                <li :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">
                                    {{item.value}}
                                    <i v-if="index===_drawingFormat().length-1 && (gameType.indexOf('lhc')===0) ">特</i>
                                </li>
                            </template>
                            <template v-else>
                                <li v-for="(item,index) in _drawingFormat()">
                                    <span class="dice" :class="'dice-'+item.value"></span>
                                </li>
                            </template>
                        </ul>
                        <a id="toggle" @click.stop="toggleComponentDocker" e v-if="_TEMPLATE==='template-2'">
                            <img :src="require('@@/assets/images/others/icons/arrow-right.png')" />
                        </a>
                    </div>

                    
                </div>
            </div>
        </template>
        <template v-if="_TEMPLATE!=='template-3'&&gameType.indexOf('28')===-1 && currentDrawing && lastDrawing && gameType!=='wanneng' && gameType!=='damacai' && gameType!=='duoduo' && gameType!=='yuedawan' && gameType!=='shaba88' && gameType!=='saimahui' && gameType!=='xinjapowz'">
            <div class="inner type2" @click='restore' :class="{covered:TEMPLATE1_componentDockerToggle||TEMPLATE2_componentDockerToggle}">
                <div class="last" v-if="!TEMPLATE2_componentDockerToggle||!TEMPLATE1_componentDockerToggle">
                    <h4>{{ getWord('number') }}<b>{{lastDrawing && lastDrawing.issue}}</b>{{ getWord('period') }}</h4>
                    <div class="result">
                        <template v-if="_drawingFormat() && !_drawingFormat().length">
                            <ul v-if="gameType==='bj28'||gameType==='jnd28'||gameType==='pc28one'" class="pc28">
                                <li v-for="item in 3">?</li><li style="color:#333333!important">?</li>
                            </ul>
                            <ul v-else-if="gameType.indexOf('fastthree')>-1" class="kuai3">
                                <li v-for="item in 3">?</li>
                            </ul>
                            <ul v-else-if="gameType==='fc3d'">
                                <li v-for="item in 3">?</li>
                            </ul>
                            <ul v-else-if="gameType==='bjsc' || gameType==='scone' || gameType==='scthree' || gameType==='scfive' || gameType==='xyft' || gameType==='azxy10'" class="dtclassic">
                                <li v-for="item in 10">?</li>
                            </ul>
                            <ul v-else-if="gameType.indexOf('ssc')>-1 || gameType==='tencent' || gameType.indexOf('fiveselect')>-1 || gameType==='gxklsf' || gameType==='jndxy' || gameType==='azxy5'">
                                <li v-for="item in 5">?</li>
                            </ul>
                            <ul v-else-if="gameType.indexOf('lhc')===0">
                                <li v-for="item in 7">?</li>
                            </ul>
                            <ul v-else-if="gameType==='gdklten' || gameType==='tiantiancai'">
                                <li v-for="item in 8">?</li>
                                <li class="tema" v-if="gameType==='tiantiancai'" v-for="item in 2">?</li>
                            </ul>
                            
                        </template>
                        <ul v-if="_drawingFormat() && _drawingFormat().length" :dxds="checkDxds(lastDrawing.num_count)" :class="{ 
                            lhc:_drawingFormat().length===7,
                            pc28:_drawingFormat().length===4,
                            'dtclassic':(gameType==='bjsc' || gameType==='scone' || gameType==='scthree' || gameType==='scfive' || gameType==='xyft' || gameType==='azxy10'),
                            'ttc':gameType==='tiantiancai',
                            kuai3:(_drawingFormat().length===3&&gameType.indexOf('fastthree')>-1), 
                            'gxklsf':gameType.indexOf('gxklsf')===0,
                        }">
                            <template v-if="gameType.indexOf('fastthree')===-1">
                                <li :class="{
                                    'pc28one':gameType==='pc28one',
                                    'tema':gameType==='tiantiancai'&&index>7,
                                }" :style="{ 
                                    '--data-color':item.color,
                                    '--data-bg-color':item.bgColor 
                                }" v-for="(item,index) in _drawingFormat()">
                                    <template v-if="gameType!=='cqklten'">
                                        {{item.value}}
                                    </template>
                                    <template v-else>
                                        <img :src="require('@@/assets/'+_TEMPLATE+'/images/tradingfloor/icons/cqklten/'+(item.value.toString().length===1?0+item.value.toString():item.value)+'.png')" />
                                    </template>
                                    <i v-if="index===_drawingFormat().length-1 && (gameType.indexOf('lhc')===0) ">特</i>
                                </li>
                            </template>
                            <template v-else>
                                <li v-for="(item,index) in _drawingFormat()">
                                    <span class="dice" :class="'dice-'+item.value"></span>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <div class="current">
                    <div class="left">
                        <h6 class="index" v-if="!isNaN(countdownTimer)&&currentDrawing"><b>{{formatCurrentDrawing(currentDrawing.issue)}}</b>{{ getWord('period') }}</h6>
                        <h4>{{formatCountdownTimer}}</h4>
                    </div>
                    <nav>
                        <a v-for="item in nav" @click='handleHeaderMenuClick(nav,item)' :style="{'--data-color':item.color}" :tag="item.tag" v-if="!(item.tag==='chatroom'&&!game.info.chatroom===false)">
                            <i class="iconfont" :class="item.icon"></i>
                            {{item.title}}
                        </a>
                    </nav>
                </div>
            </div>
        </template>
        <div class="wanneng" v-if="_TEMPLATE!=='template-3'&& (gameType==='wanneng'||gameType==='damacai'||gameType==='duoduo'||gameType==='yuedawan'||gameType==='shaba88'||gameType==='saimahui'||gameType==='xinjapowz') ">
            <div v-if="!isNaN(countdownTimer)&&currentDrawing">{{ getWord('until2') }} <span class="index">{{ formatCurrentDrawing(currentDrawing.issue) }}</span> {{ getWord('period') }}</div>
            <div v-else>&nbsp;</div>
            <div>{{ getWord('remaining') }} <span class="time">{{ formatCountdownTimer }}</span> {{ getWord('drawing_result_time') }}</div>
        </div>
        <template v-if="_TEMPLATE==='template-3' && currentDrawing && lastDrawing">
            <div class="inner" :class="{'mounted-on':mountedOn}">
                <div class="current">
                    <h6 class="index">
                        <template v-if="!isNaN(countdownTimer)">
                            {{mountedOn?getWord('until2'):getWord('number')}}<b>{{formatCurrentDrawing(currentDrawing.issue)}}</b>{{ getWord('period') }}{{mountedOn?getWord('until3'):getWord('handicap_renew')}}
                        </template>
                        <h4>
                            <template v-if="!isNaN(countdownTimer)">
                                <span class="border">{{formatCountdownTimer[0]}}</span>
                                <span class="border">{{formatCountdownTimer[1]}}</span>
                                <b>:</b>
                                <span class="border">{{formatCountdownTimer[3]}}</span>
                                <span class="border">{{formatCountdownTimer[4]}}</span>
                                <b>:</b>
                                <span class="border">{{formatCountdownTimer[6]}}</span>
                                <span class="border">{{formatCountdownTimer[7]}}</span>
                            </template>
                            <template v-else>
                                <span>{{ getWord('wait_for_handicap_renew') }}</span>
                            </template>
                        </h4>
                    </h6>                    
                    <h6 class="balance">
                        {{ getWord('balance') }}:
                        <b>
                            <img :src="require('@@/assets/template-3/images/tradingfloor/icons/gold-bar.png')" />
                            {{userBalance}}
                        </b>
                    </h6>
                </div>
                <div class="last lhc" v-if="gameType.indexOf('lhc')===0">
                    <h4>{{ getWord('number') }}<b>{{lastDrawing.issue}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length">
                            <li v-for="item in 7">?</li>
                        </ul>
                        <ul v-else>
                            <li :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }" v-for="(item,index) in _drawingFormat()" :class="item.name" :zodiac="item.zodiac||'?'">{{item.value}}</li>
                        </ul>
                    </div>
                </div>
                <div class="last ffc" v-if="gameType.indexOf('tencent')>-1 || gameType.indexOf('klsf')>-1">
                    <h4>{{ getWord('number') }}<b>{{formatCurrentDrawing(lastDrawing.issue)}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length">
                            <li v-for="item in 5">?</li>
                        </ul>
                        <ul v-else>
                            <li v-if="gameType.indexOf('tencent')>-1" :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">{{item.value}}</li>
                            <li v-if="gameType.indexOf('klsf')>-1" :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">{{Number(item.value)<10?('0'+Number(item.value)):item.value}}</li>
                        </ul>
                    </div>
                </div>
                <div class="last fc3d" v-if="gameType.indexOf('fc3d')>-1">
                    <h4>{{ getWord('number') }}<b>{{formatCurrentDrawing(lastDrawing.issue)}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length">
                            <li v-for="item in 3">?</li>
                        </ul>
                        <ul v-else>
                            <li :style="{ '--data-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">{{item.value}}</li>
                        </ul>
                    </div>
                </div>
                <div class="last classic" v-if="gameType.indexOf('ssc')>-1 || gameType==='jndxy' || gameType==='azxy5'">
                    <h4>{{ getWord('number') }}<b>{{formatCurrentDrawing(lastDrawing.issue)}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length" class="dtclassic">
                            <li v-for="item in 5">?</li>
                        </ul>
                        <ul v-else class="dtclassic">
                            <li :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">{{item.value}}</li>
                        </ul>
                    </div>
                </div>
                <div class="last fiveselect" v-if="gameType.indexOf('fiveselect')>-1">
                    <h4>{{ getWord('number') }}<b>{{formatCurrentDrawing(lastDrawing.issue)}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length">
                            <li v-for="item in 5">?</li>
                        </ul>
                        <ul v-else>
                            <li :style="{ '--data-bg-color':item.color,'--data-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">{{item.value}}</li>
                        </ul>
                    </div>
                </div>
                <div class="last fastthree" v-if="gameType.indexOf('fastthree')>-1">
                    <h4>{{ getWord('number') }}<b>{{formatCurrentDrawing(lastDrawing.issue)}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length">
                            <li v-for="item in 3">?</li>
                        </ul>
                        <ul v-else>
                            <li v-for="(item,index) in _drawingFormat()">
                                <span class="dice" :class="'dice-'+item.value"></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="last klten" v-if="gameType.indexOf('klten')>-1">
                    <h4>{{ getWord('number') }}<b>{{lastDrawing.issue}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length">
                            <li v-for="item in 8">?</li>
                        </ul>
                        <ul v-else>
                            <li :style="{ '--data-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">
                                <template v-if="gameType!=='cqklten'">
                                    {{Number(item.value)<10?('0'+Number(item.value)):item.value}}
                                </template>
                                <template v-else>
                                    <img :src="require('@@/assets/'+_TEMPLATE+'/images/tradingfloor/icons/cqklten/'+(item.value.toString().length===1?0+item.value.toString():item.value)+'.png')" />
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="last ttc" v-if="gameType.indexOf('tiantiancai')>-1">
                    <h4>{{ getWord('number') }}<b>{{lastDrawing.issue}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length">
                            <li v-for="item in 8">?</li>
                            <li v-for="item in 2" class="tema">?</li>
                        </ul>
                        <ul v-else>
                            <li :style="{ '--data-color':item.bgColor }" v-for="(item,index) in _drawingFormat()" :class="{ 'tema':index>7 }">
                                {{Number(item.value)<10?('0'+Number(item.value)):item.value}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="last pk10" v-if="gameType==='bjsc' || gameType==='scone' || gameType==='scthree' || gameType==='scfive' || gameType==='xyft' || gameType==='azxy10'">
                    <h4>{{ getWord('number') }}<b>{{lastDrawing.issue}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length" class="dtclassic">
                            <li v-for="item in 10">?</li>
                        </ul>
                        <ul v-else>
                            <li :style="{ '--data-color':item.color,'--data-bg-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">{{item.value}}</li>
                        </ul>
                    </div>
                </div>
                <div class="last pc28" v-if="gameType.indexOf('28')>-1">
                    <h4>{{ getWord('number') }}<b>{{formatCurrentDrawing(lastDrawing.issue)}}</b>{{ getWord('period') }}</h4>

                    <div class="result">
                        <ul v-if="_drawingFormat() && !_drawingFormat().length" class="noresult pc28">
                            <li :style="{ '--data-color':'rgba(0,0,0,.4)' }" v-for="item in 3">?</li><li style="color:#333333!important">?</li>
                        </ul>
                        <ul v-else class="pc28">
                            <li :style="{ '--data-color':index===3&&(Number(item.value)===13||Number(item.value)===14)?'#333333':item.color,'--data-bg-color':item.bgColor }" v-for="(item,index) in _drawingFormat()">{{item.value}}</li>
                        </ul>
                        <span class="dxds">{{checkDxds(lastDrawing.num_count).slice(1,2)}}</span>
                        <span class="dxds">{{checkDxds(lastDrawing.num_count).slice(2,3)}}</span>
                    </div>
                </div>                
            </div>
        </template>
    </section>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
    section#billboard {
        padding: .25rem;
        padding-top: 0;
        position: relative;
        z-index: 2;

        &.template-3 {
            padding:0;

            &:before {
                content:none;
            }

            .inner {
                padding: 0;

                .current {
                    display: flex;
                    justify-content: space-around;
                    text-align: center;
                    align-items: center;
                    border-bottom: 1px solid #B0B0B0;
                    margin-bottom: 0;

                    h6 {

                        &.index {
                            padding: .1rem;
                            width: 50%;
                            position: static;
                            border-right: 1px solid #B0B0B0;
                        }

                        &.balance {
                            width: 50%;
                            position: static;                            

                            b {
                                margin-top: .1rem;
                                display: flex;
                                font-size: .32rem;
                                align-items: center;
                                justify-content: center;

                                img {
                                    height: .18rem;
                                    vertical-align: middle;
                                    margin-right: .15rem;
                                }
                            }
                        }
                    }

                    h4 {
                        font-size: .32rem;
                        font-weight: normal;
                        color: #5F646E;
                        margin-top: .1rem;

                        span {
                            font-size: .3rem;

                            &.border {
                                display: inline-block;
                                color: #EC2829;
                                font-size: .32rem;
                                border: 1px solid #EEEEEE;
                                padding: 0 2px;
                                margin: 0 1px;
                            }
                        }
                    }
                }

                .last {
                    background-color: #EEEEEE;
                    padding-top: .1rem;

                    &.pc28,
                    &.ffc,
                    &.classic,
                    &.lhc,
                    &.fc3d,
                    &.fastthree,
                    &.fiveselect,
                    &.ttc {
                        padding: .1rem;
                        padding-top: 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        flex-wrap: wrap;

                        .result {
                            display: block;
                        }

                        .dxds {
                            width:.72rem;
                            height: .72rem;
                            line-height: .72rem;
                            border-radius: 100%;
                            margin-right:.1rem;
                            box-shadow: inset 0px 0px 4px 2px rgba(75,183,255,1);
                            display: inline-block;
                            font-size: .3rem;
                            text-align: center;
                            vertical-align: top;

                            &:last-child {
                                margin-right:0;
                            }
                        }

                        ul {

                            &.dtclassic {

                                li {
                                    font-weight: normal;
                                    background-color: #ffffff;
                                    box-shadow: inset 0px 0px 4px 2px #FFCD3D;    
                                }
                            }

                            &.pc28 {

                                li {

                                    &:before {
                                        margin-right:0;
                                        font-weight: normal;
                                    }

                                    &:first-child {
                                        margin-left: 0;
                                    }

                                    &:last-child {                                        
                                        background-color: transparent;
                                        /*box-shadow: inset 0px 0px 4px 2px rgba(0,0,0,.4);*/
                                    }
                                }
                            }

                            li {
                                color: #000000!important;
                                width:.72rem;
                                height: .72rem;
                                line-height: .72rem;
                                border-radius: 100%;
                                margin-left:.175rem;
                                box-shadow: inset 0px 0px 4px 2px var(--data-color);
                                font-size: .36rem;
                                font-weight: bold;
                                margin-right: .1rem;
                            }
                        }

                        h4 {
                            padding-top: .1rem;
                        }
                    }

                    &.fc3d {
                        padding:0;

                        h4 {
                            padding:0;
                        }

                        ul {
                            
                            li {
                                box-shadow: inset 0px 0px 4px 2px #EC2829;
                            }
                        }
                    }

                    &.ttc {
                        padding:0;

                        h4 {
                            padding:0;
                        }

                        ul {
                            
                            li {
                                width: .68rem;
                                height: .68rem;
                                line-height: .68rem;
                                margin: 0 1px;
                                background-color: #ffffff;
                                box-shadow: inset 0px 0px 4px 2px #FFCD3D;

                                &.tema {
                                    box-shadow: inset 0px 0px 4px 2px #216FFF;
                                }
                            }
                        }
                    }

                    &.ffc {

                        .result {

                            ul {

                                li {
                                    background-color: #ffffff;
                                    box-shadow: inset 0px 0px 4px 2px #216FFF;
                                }   
                            }
                        }
                    }

                    &.fastthree {
                        // justify-content: flex-start;
                        padding: 0 .75rem;

                        h4 {
                            margin-right: .25rem;
                            padding-top: 0;
                        }

                        ul {

                            li {
                                margin: 0 .25rem;

                                .dice {
                                    transform: scale(2);
                                }
                            }
                        }
                    }

                    &.lhc {
                        padding: 0;

                        h4 {
                            align-self: flex-end;
                            margin-bottom: .1rem;
                        }

                        .result {
                            height: auto;

                            ul {

                                li {
                                    margin: 0 .05rem;
                                    width: .58rem;
                                    height: auto;
                                    line-height: .58rem;
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    font-size: .28rem;
                                    box-shadow: none;
                                    font-weight: normal;
                                    background-image: url('/static/assets/template-3/images/tradingfloor/icons/lhc-number-grey.png');

                                    &:last-child {
                                        margin-left:.25rem;
                                    }

                                    &:after {
                                        content:attr(zodiac);
                                        display: block;
                                        font-size: .24rem;
                                        color: #333333;
                                        height: .24rem;
                                        line-height: .24rem;
                                        margin-top: 2px;
                                    }

                                    &.red {
                                        background-image: url('/static/assets/template-3/images/tradingfloor/icons/lhc-number-red.png');
                                    }

                                    &.blue {
                                        background-image: url('/static/assets/template-3/images/tradingfloor/icons/lhc-number-blue.png');
                                    }

                                    &.green {
                                        background-image: url('/static/assets/template-3/images/tradingfloor/icons/lhc-number-green.png');
                                    }
                                }
                            }
                        }
                    }

                    &.klten {

                        .result {

                            ul {

                                li {
                                    color: #000000;
                                    width: .72rem;
                                    height: .72rem;
                                    line-height: .72rem;
                                    border-radius: 100%;
                                    box-shadow: inset 0px 0px 4px 2px var(--data-color);
                                    font-size: .36rem;
                                    font-weight: bold;

                                    img {
                                        width: .7rem;
                                    }
                                }
                            }
                        }
                    }

                    .result {
                        padding: .125rem 0;
                        height: auto;
                    }

                    ul {

                        &.dtclassic {
                            justify-content: center;
                        }

                        li {
                            border-radius: 3px;
                            font-size: .3rem;
                            width: .48rem;
                            height: .48rem;
                            line-height: .48rem;
                            margin: 0 2px;
                            -webkit-text-stroke: 0.1px #333333;
                        }
                    }

                    h4 {
                        color: #797D86;

                        b {
                            color: #EC2829;
                        }
                    }
                }
            }
        }
  &.template-2 {
            padding: 0;

            &:before {
                border-bottom: 0;
            }

            .inner {
                padding: .125rem .35rem;
                border-radius: 0;
                box-shadow: none;
                position: relative;

                &.covered {

                    &:after {
                        content:'';
                    }
                }

                &.type2 {
                    padding: .2rem;

                    .current {
                        display: flex;
                        margin-bottom: .1rem;
                        padding-bottom: .1rem;

                        h6 {
                            color: #FF0F0F;
                            padding: 0;
                            width: 2rem;
                            display: flex;
						    flex-wrap: wrap;
						    justify-content: center;
                        }

                        h4 {
                            background-color: transparent;
                            margin: 0;
                            padding: 0;
                            width: 2rem;
                        }

                        nav {
                        	display: flex;
                            flex-grow: 1;

                            a {
                                display: flex;
                                padding: .18rem;
                                background-color: #F5F5F9;
                                margin-left: 0.1rem;
                                color: var(--data-color);
                                text-align: center;
                                flex-basis: 25%;
                                align-items: center;
                                word-break: break-all;

                                i {
                                    font-size: .24rem;
                                    margin-right: 0.05rem;
                                }

                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                        }
                    }

                    .last {
                        flex-direction: column;

                        h4 {
                            margin-top: 0;
                            color: $TEMPLATE2-theme-color;

                            b {
                                color: $TEMPLATE2-theme-color;
                            }
                        }

                        ul {

                            li {

                                img {
                                    width: 0.7rem;
                                }
                            }

                            &.pc28 {

                                &[dxds^="("]{

                                    li {
                                        box-shadow: none;
                                        background-color: transparent;
                                        border: .1rem solid var(--data-color);
                                        box-sizing: border-box;
                                        color: #252525;
                                        font-size: .28rem;
                                        width: .68rem;
                                        height: .68rem;
                                        line-height: .5rem;
                                        font-weight: bold;

                                        i {
                                            background-color: transparent;
                                            color: #FF0F0F;
                                            top: -.24rem;
                                            right: -.24rem;
                                        }
                                    }
                                }
                            }

                            &.dtclassic {

                                li {
                                    border-radius:5px;
                                    font-size: .36rem;
                                    font-weight: normal;
                                    width: .52rem;
                                    height: .52rem;
                                    line-height: .52rem;
                                }
                            }

                            &.gxklsf {

                                li {
                                    color: #ffffff;
                                    background-color: var(--data-color);
                                }
                            }
                        }
                    }
                }

                &:after {
                    content:none;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(0,0,0,.2);
                }
            }

            .current {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                h4,
                .timer {
                    font-size: .3rem;
                    color: #252525;
                    width: 3rem;
                    background-color: #F5F5F9;                                                
                    padding: .15rem;
                    margin-top: .1rem;
                }

                h6 {
                    font-size: .3rem;                    

                    &.index {
                        position: static;
                        // padding-left: .25rem;
                        width: 3rem;
                        text-align: center;
                        
                        b {
                            color: #FF0F0F;
                        }
                    }

                    &.balance {
                        text-align: center;
                        width: 3rem;
                        position: static;
                        flex-direction: column;
                        justify-content: flex-end;

                        b {
                            display: block;
                            background-color: #F5F5F9;                            
                            color: #252525;
                            padding: .15rem;
                            margin-top: .1rem;

                            &:before {
                                content: attr(data-currency);
                                color: #999999;
                            }
                        }
                    }
                }
            }

            .last {
                display: flex;
                justify-content: space-between;

                &.pc28 {
                	justify-content: center;
    				flex-direction: column;
                }

                h4 {
                    margin-top: 0.175rem;

                    b {
                        color: #FF0F0F;
                    }
                }

                .result {
                    padding-top: .1rem;


                }

                #toggle {
                    align-self: center;

                    img {
                        transform: rotateZ(90deg);
                        width: .2rem;
                    }
                }

                ul {

                    &.ttc {

                        li {
                            margin:0 1px;
                            background-color: #ffffff;

                            &.tema {
                                color: #ffffff;
                                background-color: $TEMPLATE2-theme-color;
                            }
                        }
                    }

                    &.pc28 {

                        &:after {
                            font-size:.36rem;
                        }

                        li {
                            font-size: .3rem;
                            width: .48rem;
                            height: .48rem;
                            line-height: .48rem;
                            margin: 0 .13rem 0 .26rem;

                            &.pc28one {
                                
                                &:last-child {
                                    color: #252525!important;
                                }
                            }
                        }
                    }
                }
            }

            .wanneng {
                color: #666666;
                font-size: .3rem;
                border-bottom: 1px solid #CCCCCC;
                display: flex;
                justify-content: space-around;
                background-color: #F9FCFF;
                padding: .2rem 0;

                span.time {
                    color: #FF5A5A
                }

                span.index {
                    color: $TEMPLATE2-theme-color;
                }
            }
        }

        &.template-1{
                       .inner {
               margin-top:10px;
           }
        }

        &.mounted-on-chatroom {
            background-color: #EEEEEE;

            .inner {
                background-color: #EEEEEE;
                box-shadow: none;

                .current {
                    border-bottom:0;

                    h6 {

                        &.index {
                            width: auto;
                            border-right: 0;
                            font-size:.3rem;
                            color: #EC2829;
                            padding:.15rem;

                            b {
                                color: #EC2829;
                            }

                            h4 {
                                display: inline-block;
                                margin-top: 0;                                

                                span {
                                    border:0;
                                    font-size: .3rem;
                                    margin:0;
                                    padding:0;
                                }
                            }
                        }

                        &.balance {
                            display: none;
                        }
                    }
                }

                .last {

                    &.pc28 {
                        flex-direction: column;
                        background-color: #ffffff;
                        margin:.25rem;
                        margin-top: 0;

                        .result {
                            width: 100%;
                            text-align: center;
                        }

                        ul {
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }

        &:before{
            content: '';
            border-bottom: .825rem solid #3985E1;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            border-radius: 0 0 50% 50%;
        }

        .inner {
            background-color: #ffffff;
            padding: .125rem;
            border-radius: 5px;
            position: relative;
            z-index: 1;
            box-shadow: 0px 1px 3px 0px rgba(57, 133, 225, 0.3);
        }

        .current {
            position: relative;
            margin-bottom: .2rem;

            h6 {
                font-size: .26rem;
                line-height: .4rem;
                color: #999999;
                font-weight: 500;

                b {
                    font-weight: 500;
                    color: #333333;
                }

                &.index {
                    position: absolute;
                    left: 0;top:0;
                }

                &.balance {
                    position: absolute;
                    right: 0;top:0;

                    b {
                        color: #E83535;
                    }
                }
            }

            h4,
            .timer {
                display: block;
                color: #E83535;
                text-align: center;
                font-size: .4rem;
                height: .4rem;
                line-height: .4rem;
                font-weight: 500;

                span {
                    font-size: .2rem;
                    color: #48BA2B;
                    vertical-align: top;                    
                }
            }
        }

        .last {

            h4 {
                font-size: .28rem;
                font-weight: 500;
                color: #999999;
                text-align: center;

                b {
                    font-weight: 500;
                    color: #333333;
                }
            }

            .result {
                height: .6rem;
                padding: .25rem 0 .125rem 0;
                display: flex;
                justify-content: center;
            }

            h6 {
                text-align: center;
                font-size: .24rem;
                color: #333333;
                line-height: .6rem;
            }

            ul {
                list-style: none;
                text-align: center;
                display: flex;
                justify-content: center;
                width: 100%;

                li {
                    font-size: .42rem;
                    font-weight: 300;
                    background-color: #ffffff;
                    border-radius: 100%;
                    margin:0 .1rem;
                    display: inline-block;
                    width: .58rem;height: .58rem;line-height: .58rem;
                    box-shadow: 0px 0px 3px 0px rgba(57, 133, 225, 0.5);
                    position: relative;
                    color: var(--data-color);
                    background-color: var(--data-bg-color);

                    img {
                        width: .58rem;
                    }
                }

                &.kuai3 {

                    li {
                        background: none!important;
                        box-shadow: none;
                        margin: 0 .25rem;

                        .dice {
                            font-size: .66rem;
                        }
                    }
                }

                &.dtclassic {
                    justify-content: space-between;

                    li {
                        margin: 0;
                    }
                }

                &.lhc {

                    li {
                        background-color: var(--data-color);
                        color: #ffffff;
                        position: relative;
                        font-size: .32rem;

                        i {
                            display: block;
                            width: .38rem;
                            height: .38rem;
                            line-height: .38rem;
                            background-color: #E83535;
                            font-size: .24rem;
                            border-radius: 100%;
                            text-align: center;
                            position: absolute;
                            top: -.2rem;
                            right: -.2rem;
                            font-style: normal;
                        }
                    }
                }

                &.pc28 {
                    display: initial;

                    &:after {
                        content:attr(dxds);
                        font-size: .42rem;
                    }

                    li {
                        font-size: .42rem;
                        font-weight: 300;
                        background-color: #ffffff;
                        border-radius: 100%;
                        margin: 0 .26rem;
                        display: inline-block;
                        width: .58rem;height: .58rem;line-height: .58rem;
                        box-shadow: 0px 0px 3px 0px rgba(57, 133, 225, 0.5);
                        position: relative;
                        color: var(--data-color);
                        background-color: var(--data-bg-color);

                        &.number-type {
                            width: auto;
                            background-color: transparent;
                            box-shadow: none;
                        }

                        &:before {
                            content:"+";
                            position: absolute;
                            right: 100%;
                            margin-right: .13rem;
                            color: #333333;
                        }

                        &:first-child {

                            &:before {
                                content:'';
                            }
                        }

                        &:last-child {
                            color: #ffffff!important;
                            background-color: var(--data-color);

                            &:before {
                                content:'=';
                            }
                        }
                    }
                }
            }

            /*.dxds {
                font-size: .42rem;
                font-weight: 300;                

                b {
                    font-weight: 300;
                    color: #E83535;
                    display: inline-block;

                    &:first-letter {
                        color: #3985E1;
                    }
                }                
            }*/
        }
    }
</style>